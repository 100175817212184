:root {
    --toastify-toast-min-height: 40px !important; /* Adjust the height */
}

.one-wallet-common-modal {
    .ant-modal-content {
        .ant-modal-body {
            .secondaryBtn {
                border: 1px solid #00a6ff !important;
                color: #00a6ff;
            }

            button {
                width: 100%;
            }
        }
    }
}

.one-wallet-common-modal .ant-modal-content {
    box-shadow: none !important;
    /* Remove the box shadow */
}

.hover-item:hover {
    background: #EFEFF4;
}

.OW-Bonus-Expand {
    position: relative;
    left: 0px;
    right: -40px;
    z-index: 99;
}

.PMAInnerCollapsable-Expand {
    max-height: 500px; /* Adjust as needed */
    overflow: visible !important;
    transition: max-height 0.3s ease-in-out;
}

#PMAInnerCollapsable:not(.PMAInnerCollapsable-Expand) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}


/* Style the tab bar */
.one-wallet-2025-applieHistory-second-tab .ant-tabs-bar {
    margin-bottom: 20px;
    border-bottom: none;
}

.one-wallet-2025-applieHistory-second-tab .ant-tabs-nav {
    border: none;
}

/* Default tab styling */
.one-wallet-2025-applieHistory-second-tab .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 16px;
    border: #E3E3E3 1px solid;
    background: #fff;
    color: #666;
    transition: background 0.3s, color 0.3s;
    border-right: none;
    font-weight: 400;
}

.one-wallet-2025-applieHistory-second-tab .ant-tabs-nav .ant-tabs-tab:first-child {
    border-radius: 10px 0px 0px 10px;
    // border: #E3E3E3 1px solid;
    border-right: none;
}

.one-wallet-2025-applieHistory-second-tab .ant-tabs-ink-bar {
    background: transparent !important;
}

/* Add border to the last tab */
.one-wallet-2025-applieHistory-second-tab .ant-tabs-nav .ant-tabs-tab:last-child {
    // border-right: 1px solid #ddd;
    border-radius: 0px 10px 10px 0px;
    // border: #E3E3E3 1px solid;
    border-left: none;
}

/* Add border to the last tab */
.one-wallet-2025-applieHistory-second-tab .ant-tabs-nav .ant-tabs-tab:nth-child(3) {
    // border-left: none;
    border-right: #E3E3E3 1px solid;
}

.one-wallet-2025-applieHistory-second-tab-wrapper .one-wallet-2025-applieHistory-second-tab .ant-tabs.border-tabs .ant-tabs-ink-bar {
    display: none;
    border-bottom: none !important;
}

/* Active tab: blue background with white text */
.one-wallet-2025-applieHistory-second-tab .ant-tabs-nav .ant-tabs-tab-active {
    background: #00A6FF !important;
    color: #fff !important;
    // border: 2px solid #00A6FF;
}

/* Hover effect */
.one-wallet-2025-applieHistory-second-tab .ant-tabs-nav .ant-tabs-tab:hover {
    background: #e6f7ff;
}

.one-wallet-2025-applieHistory-second-tab-wrapper .ant-tabs {
    border-bottom: none !important;
}



.one-wallet-2025-applieHistory-second-tab-wrapper .ant-tabs-tab-active {
    background: #00A6FF !important;
    color: white !important;
}

.custom-toast-OW-project {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F7F7 !important;
    /* Light gray background */
    color: #333 !important;
    /* Dark text color */
    border-radius: 8px;
    /* Rounded corners */
    padding: 12px 16px;
    /* Padding for spacing */
    font-size: 14px;
    box-shadow: none;
    /* Remove shadow */
}

// .Toastify__toast-container .Toastify__toast-container--top-center .OW-Toast-container {
//     // transform: none !important;
//     // min-width: 120px;
//     top: 80px !important;
// }

.Toastify__toast-container.Toastify__toast-container--top-center {
    top: 30% !important;
}


/* Style the icon */
.custom-toast-OW-project img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    /* Space between icon and text */
}

/* Ensure text doesn't overflow */
.custom-toast-OW-project div {
    white-space: pre-wrap;
    word-break: break-word;
}


.custom-toast-OW-project.yellowInfo {
    display: flex;
    align-items: center;
    background: #F7F7F7 !important;
    /* Light gray background */
    color: #333 !important;
    /* Dark text color */
    border-radius: 8px;
    /* Rounded corners */
    padding: 12px 16px;
    /* Padding for spacing */
    font-size: 14px;
    box-shadow: none;
    /* Remove shadow */
}

/* Style the icon */
.custom-toast-OW-project.yellowInfo img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    /* Space between icon and text */
}

/* Ensure text doesn't overflow */
.custom-toast-OW-project.yellowInfo div {
    white-space: pre-wrap;
    word-break: break-word;
}

.one-wallet-2025-applieHistory-second-tab-wrapper {
    .category-tabs {
        display: flex;
        gap: 8px;
        margin-bottom: 20px;
    }

    .category-tab {
        font-size: 12px;
        padding: 4px 16px;
        border: 1px solid #e3e3e3;
        border-radius: 20px;
        background-color: #ffffff;
        cursor: pointer;
    }

    .category-tab.active {
        background-color: #00a6ff1a; /* 透明度 10% */
        color: #00a6ff;
        border-color: #00a6ff;
    }

    .big-empty-box {
        margin-top: 200px;
        p {
            font-size: 18px;
        }
    }

    .last-updated {
        font-size: 12px;
        color: #a1a1a1;
        margin-bottom: 20px;
    }

    .important-note {
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 32px;

        .important-note-title {
            font-weight: 600;
            color: #222;
            margin-bottom: 8px;
        }

        .important-note-content {
            color: #666;
        }

        .highlight-bold {
            color: #222;
        }
    }
}

/* Custom dropdown container */
.custom-month-dropdown {
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Custom month cell */
.month-cell {
    padding: 12px;
    text-align: center;
    width: 100px;
    height: 50px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

/* Hover effect */
.month-cell:hover {
    background-color: #f0f0f0;
}

/* Selected month styling */
.month-cell.selected {
    background-color: #1890ff;
    color: white;
}

/* Custom confirm button */
.ant-btn-primary {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}

#productGroupOWAssignedClaimToStartForm .ant-select-selection {
    height: 50px !important; /* Match height from the mockup */
    border-radius: 5px !important; /* Rounded corners */
    display: flex !important;
    font-size: 14px !important;;
    border: 1px #E3E3E3 solid !important;
    color: #666666 !important;
    position: relative;;
}

/* Ensures text inside the select is properly aligned */
#productGroupOWAssignedClaimToStartForm .ant-select-selection-selected-value {
    font-size: 14px !important;
    color: #666666 !important;
    line-height: 50px !important; /* Align text vertically */
}

#productGroupOWAssignedClaimToStartForm .ant-select-selection__placeholder {
    color: #BCBEC3 !important; /* Placeholder color */
}

/* ✅ Ensure placeholder text appears inside input when no selection is made */
#productGroupOWAssignedClaimToStartForm.has-placeholder .ant-select-selection__rendered::before {
    content: "Chọn danh mục trò chơi"; /* ✅ Placeholder text */
    color: #BCBEC3; /* ✅ Placeholder color */
    position: absolute;
    display: flex;
    width: 150px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    pointer-events: none; /* ✅ Prevents it from being clicked */
}


/* Default state (arrow pointing down) */
#productGroupOWAssignedClaimToStartForm .custom-select-arrow {
    transition: transform 0.3s ease-in-out; /* Smooth rotation effect */
}

/* When dropdown is open, rotate the arrow */
#productGroupOWAssignedClaimToStartForm.ant-select-open .custom-select-arrow {
    transform: rotate(180deg); /* Flip the arrow */
}

/* Default styling (keeps custom border and no extra outline) */
#productGroupOWAssignedClaimToStartForm .ant-select-selection {
    border: 1px solid #E3E3E3 !important; /* Default border */
    transition: all 0.3s ease-in-out;
}

/* Remove hover effect if needed */
#productGroupOWAssignedClaimToStartForm .ant-select-selection:hover {
    border: 1px solid #E3E3E3 !important; /* Ensure border doesn't change on hover */
}

/* Fix active focus outline */
#productGroupOWAssignedClaimToStartForm .ant-select-selection:focus {
    outline: none !important;
    box-shadow: none !important;
}

/* Target the dropdown container */
#productGroupOptionOWAssignedClaimToStartForm + .ant-select-dropdown {
    border-radius: 12px !important; /* Change dropdown border-radius */
    overflow: hidden !important; /* Ensure the rounded corners work */
}

/* Style the dropdown options */
#productGroupOptionOWAssignedClaimToStartForm + .ant-select-dropdown .ant-select-dropdown-menu-item {
    color: #666666 !important;
    font-weight: 400 !important;
    text-align: start !important;
    padding: 10px 16px !important; /* Adjust padding for spacing */
}

/* Style the options on hover */
#productGroupOptionOWAssignedClaimToStartForm + .ant-select-dropdown .ant-select-dropdown-menu-item:hover {
    background-color: #f0f0f0 !important; /* Change hover effect */
    border-radius: 8px !important; /* Make individual items rounded */
}

.checkbox-container-OW {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #999999; /* Adjust color to match the grey text */
    gap: 5px;
    margin: 20px 0;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 2px solid #999999; /* Set the desired border color */
    border-radius: 4px; /* Optional: Add rounded corners */
    appearance: none; /* Remove default checkbox styling */
    outline: none;
    cursor: pointer;
    display: inline-block;
    position: relative;
}

/* Add a checkmark when checked */
input[type="checkbox"]:checked {
    border:2px solid #00A6FF; /* Border color when checked */
}

/* Add a custom checkmark */
input[type="checkbox"]:checked::after {
    content: "✔";
    font-size: 12px;
    color: #00A6FF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}

.checkbox-container-OW label {
    cursor: pointer;
}

.checkbox-container-OW strong {
    color: #222222; /* Darker text for emphasis */
    font-weight: bold;
}


#productGroupOWAssignedClaimToStartFormSelect.AssignedClaimToStartForm-select-option{
    text-align: left;
}
