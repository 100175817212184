.dailydeals-wrap {
    margin: 0 auto;
    min-width: 1120px;
    min-height: 885px;
    // overflow: hidden;
}

.dailydeals-wrap .yellowNote {
    padding: 16px;
    margin-bottom: 32px;
    margin-top: 2px;
    background-color: #FFF5BF;
    color: #83630B;
    width: fit-content;
}

.daily-banner-wrap {
    top: 168px;
    left: 400px;
    width: 1120px;
    height: 160px;
    /* UI Properties */
    // background: transparent url() 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
}

.daily-content {
    width: 1120px;
    margin: auto;
    color: #fff;
    text-align: center;
    .my-promotion-filter-box {
        .ant-radio-button-wrapper-checked,
        .ant-radio-button-wrapper-checked:hover {
            background-color: @theme;
            color: #fff !important;
            border: none !important;
            box-shadow: none !important;
            margin-bottom: 30px;
        }
    }
    .ant-tabs-tab {
        text-align: center;
        font: normal normal normal 18px/36px Roboto !important;
        letter-spacing: 0px;
        color: #999999;
        opacity: 1;
        height: 36px;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
        color: #00a5fe !important;
        text-align: center;
        font: normal normal bold 18px/24px Roboto !important;
        letter-spacing: 0px;
        color: #00a5fe;
        opacity: 1;
        height: 36px;
    }
    .ant-tabs-ink-bar {
        width: 120px;
        height: 6px;
        /* UI Properties */
        background: var(--unnamed-color-00a6ff) 0% 0% no-repeat padding-box;
        background: #00a6ff 0% 0% no-repeat padding-box;
        opacity: 1;
    }
    .ant-tabs-nav-wrap {
        justify-content: flex-start;
    }
    .daily-rewards{
        width: 100%;
    }
    .daily-deals-list {
        width: 354px;
        height: 265px;
        box-shadow: 0px 2px 4px #00000014;
        border-radius: 10px;
        opacity: 1;
        background-color: white;
        .daily-deals-list-img {
            width: 354px;
            height: auto;

            img {
                width: 100%;
                height: 100%;
                border-radius: 10px 10px 0px 0px;
                opacity: 1;
                background: transparent 0% 0% no-repeat padding-box;
            }
        }

        .daily-deals-item {
            display: flex;
            justify-content: space-between;
            // margin: 16px 0 16px 0;
            padding: 16px 0;
            //background: white;
            .daily-deals-item-left {
                width: 199px;
                height: 62px;
                position: relative;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .daily-deals-item-left-title {
                    width: 224px;
                    height: auto;
                    h5 {
                        text-align: left;
                        font: normal normal bold 16px/21px Roboto;
                        letter-spacing: 0px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* 指定显示的行数 */
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #222222;
                        opacity: 1;
                    }
                }

                .daily-deals-item-left-info {
                    padding-bottom: 5px;
                    display: flex;
                    align-items: center;
                }

                .daily-deals-item-left-icon {
                    width: 16px;
                    height: 16px;
                }

                .daily-deals-item-left-text {
                   
                    margin-left: 10px;
                    span {
                        text-align: left;
                        font: normal normal normal 12px/16px Roboto;
                        letter-spacing: 0px;
                        color: #999999;
                        opacity: 1;
                    }
                }
            }
            .daily-deals-item-btn {
                display: flex;
                align-items: center;
                margin-right: 10px;

                button {
                    height: 30px;
                    border: none;
                    background: #33c85d 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                    opacity: 1;
                    color: #ffffff;
                    letter-spacing: 0px;
                    cursor: pointer;
                    &.out-of-stock{
                        background: #cccccc 0% 0% no-repeat padding-box;
                    }
                }
            }
        }
    }
    .daily-gift-banner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        height: 160px;
        background-color: #e0e0e0;
        border-radius: 20px;
        margin-bottom: 40px;
    }
}
// 记录列表
.history-list-wrap {
    margin-top: 30px;
    margin-bottom: 50px;
    line-height: 50px;
    text-align: center;
    &._modal {
        margin-top: 0;
        margin-bottom: 20px;
    }
    &.has-detail .ant-row:not(:first-child) {
        cursor: pointer;
    }
    .ant-row {
        border-bottom: 1px solid #e0e0e0;
        color: #222;
        &:not(:first-child) {
            font-size: 12px;
        }
        .ant-col-3 {
            &:last-child {
                line-height: 25px;
            }
        }
        .lastChildSpan {
            height: 40px;
            line-height: 40px !important;
        }
    }
    .ant-col {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .anticon {
        color: #999;
    }
}
.bonusHistory{
    width: 100%;
    position: relative;
    .dailyGiftHistoryBtn{
        width: 40%;
        position: absolute;
        right: 0;
        top: -92px;
        .ant-radio-group{
            justify-content: flex-end;
        }
        .defined-date-description {
            width: 100%;
            text-align: right;
            margin-top: 15px;
            color: #222;
            span {
                margin-left: 10px;
                color: #1c8eff;
                cursor: pointer;
            }
        }
    }
}
.message-button.dailyGiftHistoryBtn {
    .ant-radio-button-wrapper {
        background: transparent;
        color: #222222;
        border: 1px solid #e0e0e0;

        &:not(:first-child) {
            border-left: none;
        }

        &.ant-radio-button-wrapper-checked {
            height: auto;
            transition: none;
            box-shadow: none;
            border: 1px solid #e0e0e0;
        }
    }

    label:first-child {
        border-radius: 5px 0px 0px 5px;
    }

    label:last-child {
        border-radius: 0px 5px 5px 0px;
    }
}
