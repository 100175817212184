.verify-modal {
    .ant-modal-content {
        border-radius: 20px;
    }
    .ant-modal-header {
        border-radius: 20px 20px 0 0;
        .ant-modal-title {
            font-size: 20px;
        }
    }
    .ant-modal-body {
        .ant-form-item-required {
            &::before {
                content: "";
            }
        }
        .StepsBox {
            width: 168px;
            .ant-steps-item {
                margin-right: 0px !important;
                .ant-steps-item-container {
                    .ant-steps-item-icon {
                        margin-right: 0px;
                    }
                    .ant-steps-item-title::after {
                        height: 2px;
                        left: 0px;
                    }
                }
            }
            .ant-steps-item-process {
                .ant-steps-item-container {
                    .ant-steps-item-icon {
                        background-color: #00a6ff;
                        .ant-steps-icon {
                            color: #ffffff;
                        }
                    }
                    .ant-steps-item-title::after {
                        background-color: #d4d4d4;
                    }
                }
            }
        }
        label > b {
            padding-left: 0.6rem;
            font-size: 14px;
        }
        .verify-remaining {
            font-size: 12px;
            margin-bottom: 15px;
        }
        .TextLightGrey {
            margin: -8px 0px 16px;
        }
        .OTP-input {
            &.has-error {
                input {
                    border-color: #eb2121;
                }
            }
            input {
                font-size: 12px;
                padding: 10.5px 7px;
            }
            .ant-input-group-addon:last-child {
                width: 100px;
                padding: 0px;
                > div {
                    width: 100%;
                    padding: 5px 0px;
                    line-height: 33px;
                }
                .resend-disabled {
                    color: #fff;
                    font-size: 12px;
                    line-height: 17px;
                }
            }
        }
        button.changeVerify {
            color: #cccccc;
            background-color: #fff;
            border-color: #cccccc;
            &.abled {
                color: #00a5fd;
                background-color: #fff;
                border-color: #00a5fd;
            }
        }
        div.changeVerify {
            text-align: center;
            > span {
                color: #00a6ff;
                cursor: pointer;
                border-bottom: 1px solid #00a6ff;
            }
        }
        .realname-image {
            margin: 11px 0 5px;
            width: 100%;
            text-align: center;
        }
        .verify-realname {
            color: #222222;
            label {
                color: #222222;
            }
            input {
                color: #222222;
            }
            button {
                height: 44px;
            }
            input::placeholder {
                color: #999999;
            }
            p {
                margin-bottom: 16px;
            }
            .verify-realname-box {
                p {
                    font-size: 12px;
                }
                .verify-realname-input {
                    margin-bottom: 16px;
                    .ant-form-explain {
                        margin-bottom: 0px;
                    }
                }
            }
            .verify-realname-btns {
                display: flex;
                gap: 10px;
                button {
                    width: 100%;
                }
            }
        }
    }
}