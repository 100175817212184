.tab2025-wrap {
    display: flex;
    cursor: pointer;
  }
  
  .tab2025-style1 {
  .tab2025-button {
    text-align: center;
    display: inline-block;
    flex: 1;
    padding: 6px 12px;
    border:1px solid #E3E3E3;
    background: #fff;
    border-left:none;
    font-size: 14px;
    color: #666;
    cursor: pointer;
    outline: none;
    width:66px;
    transition: color 0.3s ease, border-bottom 0.3s ease;
  }
  .tab2025-button:nth-child(3) {
    width:60px;
  }
  
  .tab2025-button.active {
    background: #00A6FF;
    color:#fff;
  }
  
  .tab2025-button:not(.active):hover {
  
  }
  
  .tab2025-button:first-child {
    border-left:1px solid #E3E3E3;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .tab2025-button:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  } // end of tab2025-style1
  
  .tab2025-style2 {
    .tab2025-button {
      display:inline-block;
      padding:0 16px;
      margin:0 10px 0 0;
      color:#666;
      border:1px solid #E3E3E3;
      background: #fff;
      border-radius: 20px;
      height: 24px;
      line-height: 24px;
    }
    .tab2025-button:hover{
      color:#00A6FF;
    }
    .tab2025-button.active {
      color:#00A6FF;
      border:1px solid;
      background: #00A6FF1A;
    }
  } // end of tab2025-style2