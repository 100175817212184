.DailyGiftAddressDetail {
    width: 400px !important;
    .ant-modal-title {
        color: #fff;
        text-align: center;
    }
    .ant-modal-content .ant-modal-body {
        padding: 0 30px 30px 30px;
    }
    .DailyGiftAddressDetail-text {
        display: flex;
        align-items: center;
        width: 340px;
        padding: 10px;
        background: #fff5bf 0% 0% no-repeat padding-box;
        border-radius: 8px;
        text-align: left;
        color: #83630b;
        margin-top: 30px;
        font-size: 12px;
    }
    .item-text {
        height: 20px;
        /* UI Properties */
        text-align: left;
        margin-left: 4px;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: left;
        font-weight: bold;
    }
    .ant-checkbox-inner {
        border-radius: 0;
    }
    .ant-select-selection--single {
        height: 45px;
    }
    .DailyGiftAddressDetail-btn {
        margin: 31px 0 15px;
        .ant-btn {
            height: 40px;
        }
      
    }
    .delete-btn .ant-btn{
        height: 40px;
        border-color: #00a6ff;
        color: #00a6ff;
        font-weight: bold;
    }
    // select > option 樣式
   
    .ant-input[disabled],
    .ant-select-disabled .ant-select-selection.ant-select-selection--single{
        background-color: #F4F2F2;
    }
    .ant-select-selection--single .ant-select-selection__rendered {
        display: flex;
        align-items: center;
        margin-left: 5px;
        margin-right: 5px;
        line-height: 42px;
        text-align: center;
    }
    .ant-select-selection-selected-value{
        vertical-align: bottom;
        font-size: 14px;
        color: #222222;
    }

    // prefix前綴 input 樣式
    .ant-input-affix-wrapper .ant-input-prefix {
        text-align: left;
        letter-spacing: 0px;
        color: #999999;
        opacity: 1;
    }
    .ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 8px;
    }
    .ant-modal-content {
        border-radius: 20px;
    }

    .ant-modal-header {
        border-top-right-radius: 20px;
        border-top-left-radius: 20px;
    }

    .input-error-message {
        margin-top: 10px;
    }

    .ant-select-disabled {
        .ant-select-selection-selected-value {
            color: #222;
        }
    }

    .lastname-input,
    .firstname-input {
        border: 1px solid #bfbfbf;
        border-radius: 10px;
    }
    .phone-numbner-row{
        .ant-col-4{
            border-radius: 8px;
            background: #F4F2F2;
            line-height: 45px;
            text-align:center;
        }
    }
    .selectArddress-wrap {
        .ant-select-dropdown-menu-item{
            padding: 5px;
        }
        .ant-select-arrow{
            right: 3px;
        }
    }
}
