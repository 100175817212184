.ant-modal.cookiePopup .ant-modal-confirm-body .ant-modal-confirm-content {
    text-align: left !important;
}

.ifarme-learn-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1003;
}

.ifarme-learn-wrap .tlc-learn-shadow img {
    position: absolute;
    right: 40px;
    top: -66px;
    bottom: 0;
    margin: auto;
}

.ifarme-learn-wrap .tlc-learn-shadow._2 img {
    right: 360px;
}

.ifarme-learn-wrap .learn-knew {
    margin-top: 0;
    position: absolute;
    right: 528px;
    top: 180px;
    bottom: 0;
    margin: auto;
}

.ifarme-learn-wrap .next-knew {
    right: 216px;
    background-image: url("@{base-path}/img/learn/next_step.png");
}

.tlc-user-info {
    color: #fff;
    padding: 20px 60px;
    background-color: #222;
}

.tlc-user-info .tlc-user-wallet-wrap {
    border: 0;
    padding: 0;
}

.tlc-user-info .tlc-user-picture-wrap {
    padding: 0;
}

.tlc-iframe-bar > li {
    line-height: 50px;
    height: 50px;
    color: #ddd;
    cursor: pointer;
    user-select: none;
}

.tlc-iframe-bar > li:hover,
.tlc-iframe-bar > li:active {
    background-color: #121212;
    color: #ffee00;
}

.tlc-iframe-live-btn {
    position: fixed;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 12px;
    line-height: 32px;
    cursor: pointer;
}

.tlc-iframe-live-btn .live-service {
    width: 32px;
    height: 32px;
    display: inline-block;
    vertical-align: middle;
}

.tlc-iframe-live-btn > span {
    color: #ddd;
    font-size: 16px;
    vertical-align: middle;
}

.tlc-user-wallet-wrap.draw .tlc-user-wallet {
    width: 150px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

.tlc-user-wallet-wrap.draw > .inline-block {
    cursor: pointer;
    font-size: 16px;
    height: 16px;
    line-height: 16px;
    vertical-align: middle;
}

.tlc-user-wallet-wrap.draw .tlc-all-balance {
    margin: 0;
}

iframe {
    max-width: 1400px;
    margin: 0 auto;
}

.ant-modal-confirm .ant-modal-confirm-btns {
    text-align: center;
}

.small-screen {
    width: 64px;
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    margin: auto;
}

.acreen-btn {
    color: #fff;
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    border-radius: 50%;
    font-weight: bold;
    text-indent: -200px;
    overflow: hidden;
    margin-bottom: 15px;
    background-color: rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}

.acreen-btn:hover {
    background-image: none !important;
    background-color: rgba(0, 0, 0, 0.4);
    text-indent: initial;
}

.acreen-btn.scale-large {
    background-image: url("@{base-path}/img/game/iframe/entry_full_screen.png");
}

.acreen-btn.scale-small {
    background-image: url("@{base-path}/img/game/iframe/exit_full_screen.png");
    background-color: rgba(255, 255, 255, 0.4);
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
}

.acreen-btn.page-back {
    background-image: url("@{base-path}/img/game/iframe/game_back.png");
}

.acreen-btn.scale-small.drawer {
    right: 320px;
}

.games-slider {
    width: 40px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    right: -8px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-image: url("@{base-path}/vn/img/home/part/arrow-to-left.png");
    background-position: -4px center;
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.3s;
    background-size: 100%;
    background-repeat: no-repeat;
}

.games-slider.learn {
    z-index: 1005;
    background-color: #909090;
    pointer-events: none;
}

.games-slider:hover {
    opacity: 0.8;
    right: 0px;
}

@media (max-width: 1498px) {
    .small-screen-default {
        position: fixed;
        right: 4px !important;
        top: 106px;
    }
}

@media (max-width: 1578px) {
    .small-screen-YBK {
        position: fixed;
        right: 4px !important;
        top: 106px;
    }
}
