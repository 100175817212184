.left-next,
.right-next {
    top: -80px;
    bottom: 0;
    width: 36px;
    height: 64px;
    margin: auto;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    z-index: 1;
    img {
        width: 32px;
        height: 64px;
    }
}

.left-next {
    left: 4px;
}

.right-next {
    right: 4px;
}

.crypto-lesson-wrap {
    position: relative;
    width: 100%;
    height: 323px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &.withdraw-ls-1 {
        background-image: url("@{base-path}/img/withdrawlesson/tutorial1.png");
    }
    &.withdraw-ls-2 {
        background-image: url("@{base-path}/img/withdrawlesson/tutorial2.png");
    }
    &.withdraw-ls-3 {
        background-image: url("@{base-path}/img/withdrawlesson/tutorial3.png");
    }
    &.withdraw-ls-4 {
        background-image: url("@{base-path}/img/withdrawlesson/tutorial4.png");
    }

    &.address-ls-1 {
        background-image: url("@{base-path}/img/withdrawlesson/add-tutorial1.png");
    }
    &.address-ls-2 {
        background-image: url("@{base-path}/img/withdrawlesson/add-tutorial2.png");
    }
    &.address-ls-3 {
        background-image: url("@{base-path}/img/withdrawlesson/add-tutorial3.png");
    }
    &.address-ls-4 {
        background-image: url("@{base-path}/img/withdrawlesson/add-tutorial4.png");
    }
    &.address-ls-5 {
        background-image: url("@{base-path}/img/withdrawlesson/add-tutorial5.png");
    }
}

.ant-tabs-tab {
    color: #999999;
}

// .ant-tabs-nav .ant-tabs-tab-active {
//     font-weight: 1000 !important;
//     color: #222222 !important;
//     font-size: 14px !important;
// }

.crypto-lesson-body {
    margin-left: 6rem;
    margin-right: 6rem;
}

.deposit-comment-title {
    text-align: left;
    font-weight: bold;
    font-size: 14px;
    margin-top: 18px;
    margin-bottom: 7px;
}

.deposit-comment-content {
    text-align: left;
    font-size: 12px;
}

.slick-dots .custom-Carousel {
    bottom: -5px !important;
    z-index: 999;
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li.slick-active button {
    background: #000000bf;
    border-radius: 8px;
    height: 8px;
    width: 8px;
}

.ant-carousel .custom-Carousel .slick-dots li {
    background: transparent;
}

.ant-carousel .custom-Carousel .slick-dots li button {
    background: #00000040;
    border-radius: 50%;
    height: 8px;
    width: 8px;
}

.arrowLeftRight {
    background-color: #00000040;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
}

.comment-title {
    text-align: center;
    color: #f92d2d;
    font-size: 18px;
    font-weight: 600;
    padding-top: 2rem;
}

.comment-content {
    text-align: left;
    color: #000000;
    font-weight: 500;
    font-size: 18px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 10rem;
    margin-right: 10rem;
    line-height: 20px;
    text-shadow: 0.2px 0.2px, 0.2px 0.2px, 0.2px 0.2px;
}
.ccw-withdrawLesson{
    .ant-btn {
        width: 70%;
        margin: 30px 15% 0;
    }
}

