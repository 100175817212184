.promotion-card {
    width: 100%;
    min-height: 147px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e0e0;
    border-radius: 10px;

    .promotion-card-container {
        position: relative;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 25px 15px 19px 15px;

        .promotion-info-box {
            display: flex;
            // margin-bottom: 8px;

            .promotion-card-list-img {
                width: 48px;
                height: 48px;

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 10px 10px 0px 0px;
                    opacity: 1;
                    background: transparent 0% 0% no-repeat padding-box;
                }
            }

            .promotion-card-list-title {
                margin-left: 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                padding-right: 20px;

                .promotion-card-list-title-name {
                    font-size: 14px;
                    text-align: left;
                    font-weight: bold;
                }

                .promotion-card-list-title-time {
                    height: 16px;
                    font-size: 12px;
                    text-align: left;
                    letter-spacing: 0px;
                    color: #999999;
                    opacity: 1;
                }

                .promotion-card-list-title-time-icon {
                    position: absolute;
                    right: 6%;
                    top: 15%;
                }
            }
        }

        .button-box {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .promotion-card-info-bonustext {
                color: #46a5f8;
                margin-bottom: 10px;
                span {
                    font-size: 12px;
                }
            }

            // General button setting
            > div {
                padding-left: 60px;

                button {
                    padding: 0px 33px;
                    border-radius: 4px;
                    cursor: pointer;
                    height: 30px;
                    font-size: 12px;
                }

                //客製button
                //待領取
                .promotion-card-info-btn-pending {
                    button {
                        background-color: #fff;
                        color: #00a6ff;
                        border: 1px solid #00a6ff;
                    }
                }

                //已領取 & 待派發
                .promotion-card-info-btn-disabled {
                    button {
                        background-color: #cccccc;
                        color: #fff !important;
                        border: 1px solid #fff !important;
                        cursor: not-allowed !important;
                    }
                }

                //領取紅利
                .promotion-card-release-btn {
                    button {
                        background-color: #65c469;
                        color: #fff;
                        border: 1px solid #fff;
                    }
                }
            }

            // 客製Progress box
            .progress-box {
                padding-left: 0px;

                .ProgressBar {
                    width: 100%;
                    height: 8px;
                    background-color: #e0e0e0;
                    border-radius: 15px;
                    margin: 10px 0px;

                    //現在進度
                    .Progress {
                        height: 100%;
                        background-color: #46a5f8;
                        border-radius: 8px;
                        background-image: none;
                        box-shadow: none;
                    }
                }

                .progress-text {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}

.promotion-CancellPopup {
    position: absolute;
    right: -15px;
    width: 206px;
    height: 50px;
    background: #333333 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.promotion-CancellPopupAsk {
    position: absolute;
    right: -15px;
    width: 206px;
    height: 86px;
    /* UI Properties */
    background: #333333 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    z-index: 999;
}

// 失效的優惠圖卡
.promotion-card .Invaliddata-card {
    filter: grayscale(1);
    opacity: 0.7;
}

.HidePromos-box {
    height: 16px;
    /* UI Properties */
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
}

// 流水進度條
ul,
li {
    list-style: none;
}

.progressbar_container {
    width: 100%;
    //   position: absolute;
    z-index: 1;
    margin-top: 20px;
}

.progressbar {
    counter-reset: step;
    display: flex;
}

.progressbar li {
    flex: 1;
    position: relative;
    text-align: center;
}

.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 24px;
    height: 24px;
    border: 2px solid #bebebe;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 24px;
    background: white;
    color: #bebebe;
    text-align: center;
    font-weight: bold;
}

.progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: #c4c4c4;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.progressbar li.active:before {
    border-color: #00a6ff;
    background: #00a6ff;
    color: white;
}

.progressbar li:not(:last-child) {
    &.active:before {
        content: "";
        // background-image: url('@base-path + '/img/verify/tick.png');
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.progressbar li.active:after {
    background: #00a6ff;
}

// .progressbar li.active - li:after{
//  background: #00A6FF;
// }
// .progressbar li.active - li:before{
// border-color: #00A6FF;
// background: #00A6FF;
// color: white
// }
.progressbar li:first-child:after {
    content: none;
}

// 盘口设置
.cap-list {
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: #fff;

    // &:first-child {
    //     margin-top: 20px;
    // }
    .cap-item {
        margin-bottom: 0;

        div.cap-item-circle {
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #888888;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            box-sizing: border-box;
            transition: all 0.2s;
            display: inline-block;
        }

        div.cap-item-circle.curr {
            border: 4px solid #00a6ff;
        }
    }
}

.Proms {
    // 優惠彈窗
    width: 400px !important;
    .ant-modal-header {
        background-color: #00a6ff;
        border-radius: 20px 20px 0 0;
    }

    .ant-modal-content {
        border-radius: 20px;
        background-clip: none;

        .ant-modal-close {
            color: white;

            svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    .ant-modal-title {
        color: white;
        text-align: center;
        letter-spacing: 0px;
        font-size: 20px;
        font-weight: bold;
    }

    .ant-modal-body {
        padding: 30px;

        label {
            text-align: left;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0px;
            color: #222222;
            opacity: 1;
        }
    }

    .cap-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 20px;

        .padding-left-xs {
            text-align: left;
            margin-left: 10px;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0px;
            color: #666666;
            opacity: 1;
        }
        & > input{
            width: 80%;
            border: none;
            border-radius: 0px;
            border-bottom: 1px solid #666666;
            &:focus{
                box-shadow: none;
            }
        }
    }

    .cancel-btn {
        width: 160px;
        height: 40px;
        /* UI Properties */
        font-weight: 600;
        color: #cccccc;
        background: white;
        border: 2px solid #cccccc;
        border-radius: 8px;
        opacity: 1;
        cursor: pointer;
    }
    .remarked{
        color: #00a6ff;
        border-color: #00a6ff;
    }

    .keep-btn {
        width: 160px;
        height: 40px;
        /* UI Properties */
        background: #00a6ff 0% 0% no-repeat padding-box;
        border: 1px solid #00a6ff;
        border-radius: 8px;
        color: white;
        opacity: 1;
        cursor: pointer;
    }

    .keep-btn:disabled {
        color: white;
        background-color: #cccccc;
        border: 1px solid #cccccc;
        cursor: not-allowed;
    }
}

.valid-list-box {
    margin-bottom: 10px;

    .applied-list-box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: repeat(3, 1fr);
        column-gap: 30px;
        row-gap: 30px;
        position: relative;
        margin-bottom: 30px;
    }
}

.invalid-list-box {
    margin-bottom: 50px;
    p {
        text-align: center;

        span {
            color: #46a5f8;
            cursor: pointer;
            margin-left: 10px;
        }
    }

    .invalid-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
        row-gap: 30px;
        position: relative;
        margin-bottom: 30px;

        .promotion-card-disabled {
            .promotion-card-container {
                img {
                    filter: grayscale(100%);
                }
                p,
                span {
                    color: #bcbec3;
                }
                button {
                    color: #bcbec3 !important;
                    border-color: #bcbec3 !important;
                    cursor: default !important;
                }

                // 客製Progress box
                .progress-box {
                    .ProgressBar {
                        //現在進度
                        .Progress {
                            background-color: #bcbec2;
                        }
                    }

                    .progress-text {
                        display: flex;
                        justify-content: space-between;
                        span {
                            color: #bcbec3 !important;
                        }
                    }
                }
            }
        }

        .button-box {
            // height: 60px
        }
    }
}

// 已申請優惠 Popover 樣式
.appliedHistory-popover {
    .ant-popover-arrow {
        border-top-color: #333333 !important;
        border-left-color: #333333 !important;
    }

    .ant-popover-inner {
        background-color: #333333;
    }

    .ant-popover-inner-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .popover-text-container {
            display: flex;
            justify-content: space-between;
            position: relative;

            .promotion-CancellPopupAsk-text {
                padding-bottom: 30px;

                .promotion-CancellPopupAsk-btn {
                    position: absolute;
                    right: 0%;
                    bottom: 0%;

                    button {
                        border: 0;
                        padding: 5px 20px;
                        /* UI Properties */
                        background: #ffffff 0% 0% no-repeat padding-box;
                        border-radius: 4px;
                        text-align: center;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0px;
                        color: #222222;
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }

            .promotion-CancellPopup-text {
                width: 200px;
                text-align: left;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
            }

            .promotion-CancellPopup-close {
                width: 24px;
                height: 18px;
                text-align: center;
                cursor: pointer;

                svg {
                    color: white;
                }
            }
        }
    }
}
