.top-svg {
    position: absolute;
    right: 0;
    width: 80px;
    height: 35px;
}

.list-content img {
    height: 100px;
}

.green {
    font-size: 12px;
    margin: 0px 10px;
    padding: 2px 5px;
    text-align: center;
    background: #6dd400 0% 0% no-repeat padding-box;
    border-radius: 2px;
}

.hot {
    font-size: 12px;
    margin-right: 10px;
    padding: 2px 5px;
    text-align: center;
    background: #fa6400 0% 0% no-repeat padding-box;
    border-radius: 2px;
}

.click-btn .open {
    color: #222222;
    width: 100%;
    font-size: 12px;
}

.click-btn .try {
    color: #f7b500;
    border-left: 1px solid #99999929;
}

.click-btn {
    display: flex;
    background: white;
    font: Bold 14px/14px Roboto;
    height: 36px;
    align-items: center;
}

.click-btn div {
    width: 50%;
    cursor: pointer;
}

.topbanner img {
    width: 100%;
    border-radius: 34px;
    padding: 0 20px;
}

.box-list {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    .active {
        .DefaultTag {
            width: 60px !important;
            height: 32px !important;
            font-weight: normal !important;
        }
        .openGame {
            &:hover {
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.4);
                    width: 100%;
                    height: 215px;
                    z-index: 2;
                }
                &::after {
                    content: "Chơi Ngay";
                    font-size: 14px;
                    position: absolute;
                    top: 40%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 96px;
                    height: 44px;
                    background: transparent
                        linear-gradient(180deg, #79d1ff 0%, #00a6ff 100%) 0% 0%
                        no-repeat padding-box;
                    border-radius: 30px;
                    z-index: 2;
                    color: #fff;
                }
            }

            .GameName {
                height: 79px;
                display: flex;
                align-items: center;
                font-size: 24px;
                line-height: 28px;
                white-space: normal;
                padding: 0 0 0 24px;
                z-index: 3;
                text-transform: capitalize;
            }
        }
    }
}

.RecommendGames > div {
    .openGame {
        &:hover {
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.4);
                width: 100%;
                height: 215px;
                z-index: 2;
            }
            &::after {
                content: "Chơi Ngay";
                font-size: 14px;
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 96px;
                height: 44px;
                color: #fff;
                background: transparent
                    linear-gradient(180deg, #79d1ff 0%, #00a6ff 100%) 0% 0%
                    no-repeat padding-box;
                border-radius: 30px;
                z-index: 2;
            }

            .GameName {
                z-index: 3;
            }
        }
    }
}

.box-list section {
    margin-bottom: 17px;
    margin-right: 15px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #ddd;
    color: #fff;
    font-size: 36px;
    text-transform: uppercase;
    overflow: hidden;
    position: relative;
}

.box-list section .title {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-left: 10px;
    min-height: 30px;
    bottom: 36px;
    font: Bold 14px/29px Roboto;
    background: rgba(0, 0, 0, 0.5);
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.LiveCasino,
.P2P,
.Slot {
    .active {
        height: 295px !important;
    }
    section {
        height: 140px !important;
    }
}
.list-content {
    position: relative;
    overflow: hidden;
    margin-right: -10px;
}
.menu-bar-children {
    position: relative;
    .isHot {
        &::before {
            content: "";
            font-size: 18px;
            position: absolute;
            top: 3%;
            left: 7%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background: transparent;
            background-image: url("@{base-path}/img/icon/hot.svg");
            z-index: 2;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .isNew {
        &::before {
            content: "";
            font-size: 18px;
            position: absolute;
            top: 3%;
            left: 7%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background: transparent;
            background-image: url("@{base-path}/img/icon/new.svg");
            z-index: 2;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

.list-content section {
    width: 165px;
    height: 137px;
    float: left;
    box-shadow: 0px 0px 3px #99999929;
}

.list-content .active {
    width: 349px;
    height: 290px;
}
.list-content section#VTG {
    width: 165px;
    height: 161px;
}
.list-content .active#VTG {
    width: 351px !important;
    height: 338px !important;
}
// .list-content section#SPR {
//     width: 165px;
//     height: 161px;
// }
// .list-content .active#SPR {
//     width: 351px !important;
//     height: 338px !important;
// }

.list-content .active img {
    height: 215px;
}

.list-content .active .click-btn div {
    font: Bold 17px/17px Roboto;
}

.list-content .active .title {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 74px;
    font: Bold 24px/45px Roboto;
    text-align: left;
}

.list-content .active .click-btn {
    height: 72px;
}

.list-content .active .title .hot,
.list-content .active .title .green {
    font-size: 18px;
    vertical-align: middle;
}

.game-type {
    display: flex;
    flex-flow: wrap;
    margin-top: 15px;
    margin-bottom: 30px;
    justify-content: space-between;

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        min-height: 31px;
        .name {
            display: block;
            padding: 0px 3px;
        }
    }
    .isNew {
        &::after {
            content: "NEW";
            display: block;
            border-radius: 4px;
            height: 18px;
            line-height: 18px;
            border: 1px solid #ff4141;
            background-color: #ff4141;
            position: absolute;
            right: -4px;
            top: -13px;
            color: white;
            font-size: 8px;
            padding: 0 2px;
        }
    }
    .isHot {
        &::after {
            content: "HOT";
            display: block;
            border-radius: 4px;
            height: 18px;
            line-height: 18px;
            border: 1px solid #fdb454;
            background-color: #fdb454;
            position: absolute;
            right: -6px;
            top: -13px;
            color: white;
            font-size: 8px;
            padding: 0 2px;
        }
    }
}

.menu-list .game-type li.curr {
    background-color: #00a6ff;
    color: #fff;
    border: 1px solid #00a6ff;
}

.game-type li:hover {
    background-color: #00a6ff38;
    color: #00a6ff;
    border: 1px solid #00a6ff;
}

.game-type i {
    width: 24px;
    height: 18px;
    vertical-align: -0.28em;
    background-size: 100% auto;
    display: inline-block;
}

.game-type li.curr i.BGG {
    background-image: url("@{base-path}/img/game/icon/Casino/BG-c.png");
}

.game-type li.curr i.GPI {
    background-image: url("@{base-path}/img/game/icon/Casino/GP-c.png");
}

.game-type li.curr i.AGL {
    background-image: url("@{base-path}/img/game/icon/Casino/AG-c.png");
}

.game-type li.curr i.GDL {
    background-image: url("@{base-path}/img/game/icon/Casino/gd-c.png");
}

.game-type li.curr i.SAL {
    background-image: url("@{base-path}/img/game/icon/Casino/SA-c.png");
}

.game-type li.curr i.ABT {
    background-image: url("@{base-path}/img/game/icon/Casino/AB-c.png");
}

.game-type li.curr i.NLE {
    background-image: url("@{base-path}/img/game/icon/Casino/N2-c.png");
}

.game-type li.curr i.YBL {
    background-image: url("@{base-path}/img/game/icon/Casino/YBL-c.png");
}

.game-type li.curr i.EVO {
    background-image: url("@{base-path}/img/game/icon/Casino/EVO-c.png");
}

.game-type li.curr i.MGSQF {
    background-image: url("@{base-path}/img/game/icon/Slot/MGSQF_c.png");
}

.game-type li.curr i.PT {
    background-image: url("@{base-path}/img/game/icon/Slot/PT_c.png");
}

.game-type li.curr i.TG {
    background-image: url("@{base-path}/img/game/icon/Slot/TG_c.png");
}

.game-type li.curr i.DTG {
    background-image: url("@{base-path}/img/game/icon/Slot/DTG_c.png");
}

.game-type li.curr i.SPG {
    background-image: url("@{base-path}/img/game/icon/Slot/SPG_c.png");
}

.game-type li.curr i.CW {
    background-image: url("@{base-path}/img/game/icon/Slot/CW_c.png");
}

.game-type li.curr i.BSG {
    background-image: url("@{base-path}/img/game/icon/Slot/BSG_c.png");
}

.game-type i.BGG {
    background-image: url("@{base-path}/img/game/icon/Casino/BG-n.png");
}

.game-type i.GPI {
    background-image: url("@{base-path}/img/game/icon/Casino/GP-n.png");
}

.game-type i.AGL {
    background-image: url("@{base-path}/img/game/icon/Casino/AG-n.png");
}

.game-type i.GDL {
    background-image: url("@{base-path}/img/game/icon/Casino/gd-n.png");
}

.game-type i.SAL {
    background-image: url("@{base-path}/img/game/icon/Casino/SA-n.png");
}

.game-type i.ABT {
    background-image: url("@{base-path}/img/game/icon/Casino/AB-n.png");
}

.game-type i.NLE {
    background-image: url("@{base-path}/img/game/icon/Casino/N2-n.png");
}

.game-type i.MGSQF {
    background-image: url("@{base-path}/img/game/icon/Slot/MGSQF.png");
}

.game-type i.PT {
    background-image: url("@{base-path}/img/game/icon/Slot/PT.png");
}

.game-type i.YBL {
    background-image: url("@{base-path}/img/game/icon/Casino/YBL.png");
}

.game-type i.EVO {
    background-image: url("@{base-path}/img/game/icon/Casino/EVO.png");
}

.game-type i.IMOPT {
    background-image: url("@{base-path}/img/game/icon/Slot/PT.png");
}

.game-type i.TG {
    background-image: url("@{base-path}/img/game/icon/Slot/TG.png");
}

.game-type i.DTG {
    background-image: url("@{base-path}/img/game/icon/Slot/DTG.png");
}

.game-type i.SPG {
    background-image: url("@{base-path}/img/game/icon/Slot/SPG.png");
}

.game-type i.CW {
    background-image: url("@{base-path}/img/game/icon/Slot/CW.png");
}

.game-type i.BSG {
    background-image: url("@{base-path}/img/game/icon/Slot/BSG.png");
}
.game-type i.PGS {
    background-image: url("@{base-path}/img/game/icon/Slot/PGS.png");
    vertical-align: -2px;
    margin-right: 3px;
}
.game-type li {
    width: 47%;
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin: 7px 0px;
    cursor: pointer;
    font-size: 12px;
    padding: 5px 0px;
    color: #999999;
}

.game-type li img {
    width: 25px;
}

.top-title {
    border-bottom: 1px solid #9e9e9e66;
    margin: 10px 10px 25px 10px;
}

.top-title h3 {
    color: #222222;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
}

.lobby-box-content {
    display: flex;
    justify-content: center;
    //min-height: 1280px;

    &.lobby-box-content_slot {
        // height: 1300px;
        padding-bottom: 30px;
    }
}
.games_search {
    svg {
        fill: #999999;
    }
}
.games-menu {
    height: auto;
    width: 20%;
    padding: 10px 20px 0 0;
    // max-height: 763px;
    position: relative;
    & > .games-menu_search {
        height: 40px;
        border-radius: 10px;
        margin-bottom: 30px;
        box-shadow: 0px 0px 2px #00000029;

        .ant-input-wrapper {
            height: 40px;
            .ant-input {
                padding-left: 28px;
            }

            .ant-input-search,
            .ant-input-search-button {
                height: 40px;
            }

            .ant-input,
            .ant-input:hover {
                border: 2px solid #00a6ff;
                border-right-width: 0;
            }

            .ant-input-search-button:hover {
                background-color: #00a6ff;
                border-color: transparent;
            }
        }
    }
}

.games-content {
    min-height: 600px;
    width: 80%;
    position: relative;
}

.games-content h2 {
    padding: 0 10px;
}

.filterSlot {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 120px;
    height: 32px;

    .ant-select {
        width: 100%;
        height: 100%;

        .ant-select-selection {
            border-radius: 5px;

            .ant-select-selection__rendered {
                display: flex;
                justify-content: center;

                .ant-select-selection-selected-value {
                    font-size: 12px;
                }
            }

            .ant-select-arrow-icon {
                position: relative;
                top: -2px;
            }
        }
    }
}

.menu-list {
    height: auto;
    background: white;
    border-radius: 10px;
    padding: 30px 10px;
    box-shadow: 0px 0px 3px #00000029;

    &.vtg-menu-list {
        .game-type {
            margin-bottom: 0;
        }
    }
}

.menu-list h4 {
    font-weight: bold;
    color: #222;
}

.clear-search {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clear-search div {
    color: #0091ff;
    cursor: pointer;
}

// .list-box {
// 	margin: 10px;
// 	height: 100%;
// }

// .list-box ul {
// 	display: flex;
// 	flex-wrap: wrap;
// }

// .list-box li {
// 	width: 175px;
// 	height: 150px;
// 	background: white;
// }

// .list-content.Casino img,
// .list-content.P2P img {
// 	height: 162px;
// }

// .list-content.Casino section,
// .list-content.P2P section {
// 	height: 200px;
// 	border-radius: 24px;
// }

// .list-content.Casino .active img,
// .list-content.P2P .active img {
// 	height: 334px;
// }

// .list-content.Casino .active section,
// .list-content.P2P .active section {
// 	height: 408px;
// 	border-radius: 46px;
// }

// .list-content.Casino .active,
// .list-content.P2P .active {
// 	height: 408px;
// 	border-radius: 46px;
// }

// .list-content.Casino .active .title,
// .list-content.P2P .active .title {
// 	position: absolute;
// 	left: 0;
// 	bottom: 72px;
// }

// .list-content.Casino .active .click-btn,
// .list-content.P2P .active .click-btn {
// 	height: 70px;
// }

.ant-divider {
    margin: 10px 0 !important;
}

.games-content .ant-empty {
    width: 285px;
    margin: 120px auto;
}

.games-content .ant-empty-image {
    width: auto;
    height: 150px;
    img {
        width: 135px;
        height: auto;
    }
}

.PaginationBox {
    display: flex;
    justify-content: flex-start;
    padding-top: 15px;
}
.DefaultTag {
    top: 10px;
    left: 10px;
    width: 33px !important;
    height: 17px !important;
    color: white;
    position: absolute;
    font-size: 10px;
    font-weight: normal;
    z-index: 2;
    span {
        background-color: rgb(0, 166, 255);
        width: 100%;
        display: flex;
        border-radius: 4px;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}

.forGameLobby {
    &.small-option {
        overflow: hidden;
        border-radius: 5px;

        .ant-select-dropdown-menu {
            padding: 0;

            .ant-select-dropdown-menu-item-selected {
                font-weight: normal;
                background-color: transparent;
            }

            .ant-select-dropdown-menu-item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                font-size: 12px;
            }
        }
    }
}

.gameLobby-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
        a {
            background-color: transparent;
            border: 1px solid #e0e0e0;
            color: #222222;
            border-radius: 5px;
        }

        &:hover,
        &:active,
        &:focus {
            a {
                border: 1px solid #e0e0e0;
                color: #222222;
                border-radius: 5px;
            }
        }
    }

    .ant-pagination-item {
        background-color: transparent;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        a {
            color: #222222;
        }

        &:hover,
        &:focus {
            border: 1px solid #ffffff;
            border-radius: 5px;
            background-color: #ffffff;
            a {
                color: #222222;
            }
        }
    }

    .ant-pagination-item-active {
        background-color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 5px;
        a {
            color: #222222;
        }
    }

    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
        .ant-pagination-item-container {
            .ant-pagination-item-link-icon {
                color: #e0e0e0;
            }
        }
    }
}

.gamesMaintopContainer {
    width: 1120px;
    margin: 34px auto 30px auto;
}
