.lesson-box{
    &.deposit-ctc{
        .crypto-lesson-wrap {
            position: relative;
            width: 100%;
            height: 324px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
    
            &.rapid-ls-1 {
                background-image: url("@{base-path}/img/depositlesson/CTC/rapid-ls-1.png");
            }
    
            &.rapid-ls-2 {
                background-image: url("@{base-path}/img/depositlesson/CTC/rapid-ls-2.png");
            }
    
            &.rapid-ls-3 {
                background-image: url("@{base-path}/img/depositlesson/CTC/rapid-ls-3.png");
            }
    
            &.rapid-ls-4 {
                background-image: url("@{base-path}/img/depositlesson/CTC/rapid-ls-4.png");
            }
            &.rapid-ls-5 {
                background-image: url("@{base-path}/img/depositlesson/CTC/rapid-ls-5.png");
            }
    
            &.otc-ls-1 {
                background-image: url("@{base-path}/img/depositlesson/CTC/otc-ls-1.png");
            }
    
            &.otc-ls-2 {
                background-image: url("@{base-path}/img/depositlesson/CTC/otc-ls-2.png");
            }
    
            &.otc-ls-3 {
                background-image: url("@{base-path}/img/depositlesson/CTC/otc-ls-3.png");
            }
            &.otc-ls-4 {
                background-image: url("@{base-path}/img/depositlesson/CTC/otc-ls-4.png");
            }
            &.otc-ls-5 {
                background-image: url("@{base-path}/img/depositlesson/CTC/otc-ls-5.png");
            }
        }
    }
    &.deposit-phc {
        .phc-lesson-wrap {
            position: relative;
            width: 100%;
            height: 324px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            margin-top: 30px;
            &.rapid-ls-1 {
                background-image: url("@{base-path}/img/depositlesson/PHC/Rectangle1.png");
            }
    
            &.rapid-ls-2 {
                background-image: url("@{base-path}/img/depositlesson/PHC/Rectangle2.png");
            }
    
            &.rapid-ls-3 {
                background-image: url("@{base-path}/img/depositlesson/PHC/Rectangle3.png");
            }
    
            &.rapid-ls-4 {
                background-image: url("@{base-path}/img/depositlesson/PHC/Rectangle4.png");
            }
            &.rapid-ls-5 {
                background-image: url("@{base-path}/img/depositlesson/PHC/Rectangle5.png");
            }
        }
    }
    .left-next,
    .right-next {
        top: 0;
        bottom: 0;
        width: 36px;
        height: 100px;
        margin: auto;
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        z-index: 1;
        img {
            width: 32px;
            height: 64px;
        }
    }

    .left-next {
        left: 4px;
    }

    .right-next {
        right: 4px;
    }

    .ant-tabs-tab {
        color: #999999;
    }

    .ant-tabs-nav .ant-tabs-tab-active {
        font-weight: 1000 !important;
        color: #222222 !important;
    }

    .crypto-lesson-body {
        margin-left: 6rem;
        margin-right: 6rem;
    }

    .deposit-comment-title {
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        margin-top: 25px;
        margin-bottom: 10px;
        min-height: 16px;
    }

    .deposit-comment-content {
        text-align: left;
        font-size: 12px;
        min-height: 16px;
        color: #222;
    }

    .custom-Carousel {
        .slick-dots {
            bottom: -5px !important;
            z-index: 999;

            button {
                margin-right: 5px;
            }
        }
    }

    .ant-carousel .custom-Carousel .slick-dots li.slick-active {
        background: transparent;
    }

    .ant-carousel .custom-Carousel .slick-dots li.slick-active button {
        background: #000000bf;
        border-radius: 50%;
        height: 8px;
        width: 8px;
    }

    .ant-carousel .custom-Carousel .slick-dots li {
        background: transparent;
    }

    .ant-carousel .custom-Carousel .slick-dots li button {
        background: #00000040;
        border-radius: 50%;
        height: 8px;
        width: 8px;
    }

    .arrowLeftRight {
        background-color: #00000040;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
    }

    .comment-title {
        text-align: center;
        color: #f92d2d;
        font-size: 18px;
        font-weight: 1000;
        padding-top: 2rem;
    }

    .comment-content {
        text-align: left;
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        margin-top: 3rem;
        margin-bottom: 3rem;
        margin-left: 10rem;
        margin-right: 10rem;
        line-height: 20px;
        text-shadow: 0.2px 0.2px, 0.2px 0.2px, 0.2px 0.2px;
    }

    .deposit-comment-button,
    .deposit-comment-button:active,
    .deposit-comment-button:focus {
        background-color: @theme;
        text-align: center;
        color: white;
        border-radius: 8px;
        border: 1px solid @theme;
        font-size: 14px;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 5px;
        padding-bottom: 5px;
        width: 334px;
        height: 40px;
        margin: 10px auto;
    }

    .titleHeader {
        background-color: #00a6ff;
        color: white;
    }

}