.DailyGiftAddress {
    width: 400px !important;

    .ant-modal-content {
        border-radius: 20px;

        .ant-modal-header {
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;

            .ant-modal-title {
                color: #fff;
                text-align: center;
            }
        }

        .ant-modal-body {
            padding-bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .DailyGiftAddress-info {
        margin: 24px auto;
        width: 343px;
        height: 65px;
        /* UI Properties */
        background: #fff5bf 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        padding: 12px 16px;
        text-align: left;
        font: normal normal bold 12px/20px Roboto;
        letter-spacing: 0px;
        color: #83630b;
    }
    .DailyGiftAddress-list {
        display: flex;
        align-items: center;
        width: 340px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccccc;
        border-radius: 10px;
        opacity: 1;
        padding: 20px 0;
        .radioBox {
            margin-left: 10px;
            width: 20px;
            height: 20px;
        }
        .DailyGiftAddress-list-info {
            position: relative;
            width: calc(100% - 50px);
            overflow: hidden;
            margin-left: 10px;
           .mid-info-nameAndPhone{
                margin-bottom: 10px;
                width: calc(100% - 100px);
                .name{
                    color: #222222;
                    font-weight: bold;
                    margin-right: 10px;
                }
                .phone{
                    font-size: 12px;
                    color: #999999;
                }
           }
           .mid-info-address{
                text-align: left;
                font: normal normal normal 12px/16px Roboto;
                letter-spacing: 0px;
                color: #666666;
                opacity: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient:vertical;
                -webkit-line-clamp:2;
            }
            .editBox {
                position: absolute;
                cursor: pointer;
                width: 16px;
                height: 16px;
                top: 0;
                right: 0;
                opacity: 0.6;
            }
            div:first-child,
            div:nth-child(3) {
                display: flex;
                flex-direction: column;

                span {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                }
            }
        }
        .default {
            position: absolute;
            right: 50px;
            top: 20px;
            padding: 5px;
            border-radius: 2px;
            font: normal normal normal 10px/14px Roboto !important;
            color: #0CCC3C;
            display: block;
            width: 70px;
            background: url("@{base-path}/img/icon/greenTick.svg") 0  center no-repeat;
            padding-left: 20px;
        }
    }
    .DailyGiftAddress-btn {
        display: flex;
        justify-content: center;
        // margin-bottom: 10px;

        button {
            letter-spacing: -2px;
            width: 340px;
            height: 40px;
            padding: 10px 0;
            border: 1px solid var(--unnamed-color-00a6ff);
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #00a6ff;
            border-radius: 10px;
            opacity: 1;
            font: normal normal normal 14px/22px Roboto;
            letter-spacing: 0px;
            color: #00a6ff;
            cursor: pointer;
        }
    }
    .DailyGiftAddress-remark {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 340px;
        // margin-bottom: 30px;
        div{
            display: flex;
            justify-content: space-between;
            margin: 10px 0px;
            color: #222222;
            opacity: 1;
            font-size: 14px;
        }
    }
    .DailyGiftAddress-submit {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        // margin-bottom: 30px;
        button {
            width: 340px;
            height: 40px;
            padding: 10px 0;
            background: #00a6ff 0% 0% no-repeat padding-box;
            border-radius: 8px;
            opacity: 1;
            font: normal normal normal 14px/22px Roboto;
            font-weight: bold;
            color: #ffffff;
            cursor: pointer;
            margin-top: 30px;
        }
    }
}
