.drawer-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 0%;
    height: 0%;
    transition: width 0s ease 0.3s, height 0s ease 0.3s;
    z-index: 1001;
}
.drawer-wrap.drawer-open.learn {
    z-index: 1004;
    pointer-events: none;
}
.drawer-wrap.drawer-open.learn .drawer-mask {
    background-color: transparent;
}
.drawer-wrap .drawer-content-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    z-index: 199;
    overflow: auto;
    background-color: #fff;
    transform: translate(100%, 0);
    transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7),
        box-shadow 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
}
.drawer-wrap.drawer-open .drawer-content-wrapper {
    overflow: visible;
}
.drawer-content-wrapper .drawer-content {
    height: 100%;
    overflow: auto;
    padding-bottom: 60x;
    background-color: #000000;
}
.drawer-wrap .drawer-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background-color: rgba(0, 0, 0, 0.65);
    opacity: 0;
    z-index: 99;
    filter: alpha(opacity=50);
    transition: opacity 0.3s linear, height 0s ease 0.3s;
}
.drawer-wrap.drawer-open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: none;
}
.drawer-wrap.drawer-open .drawer-mask {
    height: 100%;
    opacity: 0.5;
    transition: none;
    animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.drawer-wrap.drawer-open .drawer-content-wrapper {
    transform: translate(0, 0);
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.games-slider {
    width: 30px;
    height: 100px;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    right: 300px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-image: url("@{base-path}/img/home/part/arrow-to-right.png");
    background-position: 0px center;
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.3s;
    background-size: 100%;
    background-repeat: no-repeat;
}
.games-slider.inner:hover {
    opacity: 0.8;
    right: 300px;
}
@keyframes antdDrawerFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}
