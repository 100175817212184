.footer-platform-wrap {
    background-color: #eeeff0;
    padding-top: 36px;
    padding-bottom: 36px;
    background-image: linear-gradient(0deg, #eeeff0, #f8f9fa);
    .ant-select {
        font-size: 12px;
    }
    .ant-select-selector {
        margin-top: 2px;
        border-radius: 6px !important;
        padding-left: 34px !important;
        background-image: url("@{base-path}/img/home/sprite20220315.png");
        background-repeat: no-repeat;
        background-position: -285px -288px;
    }
    h5 {
        color: #666666;
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .footer-platform {
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: 1px solid #cccccc;
    }
    .icon-list {
        line-height: 26px;
        display: block;
        &.pay-kind, &.about-we, &.regulation {
            display: block; /* override promotions.less display:flex */
        }
    }
    .icon-item1 {
        margin:5px 30px 5px 0;
        vertical-align: middle;
        display: inline-block;
        background-repeat: no-repeat;
        cursor: pointer;
        &._1 {
            width: 56px; height: 16px;
            background-position: -10px -46px;
            &:hover {
                background-position:  -10px -10px;
            }
        }
        &._2 {
            width: 62px; height: 20px;
            background-position:  -10px -228px;
            &:hover {
                background-position: -10px -350px;
            }
        }
        &._3 {
            width: 54px; height: 16px;
            background-position: -10px -118px;
            &:hover {
                background-position:  -10px -82px;
            }
        }
        &._4 {
            width: 59px; 
            height: 21px;
            background-position: -10px -309px;
            &:hover {
                background-position: -10px -268px;
            }
        }
        &._5 {
            width: 35px; 
            height: 24px;
            background-position: -10px -436px;
            &:hover {
                background-position:  -10px -392px;
            }
        }
        &._6 {
            width: 55px; 
            height: 17px;
            background-position: -10px -191px;
            &:hover {
                background-position: -10px -154px;
            }
        }
    }
    .icon-item2{
        margin-right: 10px;
        vertical-align: middle;
        display: inline-block;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 47px 408px;
        &._7{
            width: 24px; 
            height: 21px;
            background-position:  -10px -213px;
            &:hover{
                background-position: -10px  -377px;
            }
        }
        &._8{
            width: 27px; 
            height: 21px;
            background-position: -10px -131px;
            &:hover{
                background-position: -10px -336px;
            }
        }
        &._9{
            width: 23px; 
            height: 21px;
            background-position: -10px -254px;
            &:hover{
                background-position: -10px -295px;
            }
        }
        &._10{
            width: 20px; 
            height: 20px;
            background-position: -10px -10px;
            &:hover{
                background-position: -10px -50px;
            }
        }
        &._11{
            width: 26px; 
            height: 21px;
            background-position: -10px -90px;
            &:hover{
                background-position: -10px -172px;
            }
        }
    }
    .icon-item3{
        margin-right: 20px;
        vertical-align: middle;
        display: inline-block;
        cursor: pointer;
        background-repeat: no-repeat;
        background-size: 147px 274px;
        &._12{
            width: 24px; 
            height: 26px;
            background-position: -10px -192px;
            &:hover{
                background-position: -10px -146px;
            }
        }
        &._13{
            width: 27px; 
            height: 26px;
            background-position: -10px -238px;
            &:hover{
                background-position: -10px -100px;
            }
        }
        &._14{
            width: 127px;
            height: 25px;
            background-position: -10px -55px;
            &:hover{
                background-position: -10px -10px;
            }
        }
    }
    .select-language-box{
        width: 90%;
        height: 30px;
        &.ant-select-lg .ant-select-selection--single{
            height: 30px;
            border-radius: 15px;
        }
        .ant-select-selection__rendered{
            position: relative;
            height: 30px;
            line-height: 30px;
            img{
                width: 18px;
                height: auto;
            }
            span{
                margin-left: 5px;
            }
            .ant-select-selection-selected-value{
                margin-left: 23px;
                &::before{
                    content: "";
                    width: 18px;
                    height: 18px;
                    background: url("@{base-path}/img/footer/language/Vietnam.svg") 0 center no-repeat;
                    background-size: cover;
                    position:absolute;
                    left: 0;
                    top: 5px;
                }
            }
        }
        .ant-select-arrow {
            top: 40%;
            img{
                width: 22px;
                height: 22px;
            }
        }
        &.Thailand{
            .ant-select-selection-selected-value{
                margin-left: 0px;
                &::before{
                    background: url("@{base-path}/img/footer/language/Thailand.svg") 0 center no-repeat;
                }
            }
        }
        &.China{
            .ant-select-selection-selected-value{
                margin-left: 0px;
                &::before{
                    background: url("@{base-path}/img/footer/language/China.svg") 0 center no-repeat;
                }
            }
        }
        &.Vietnam{
            .ant-select-selection-selected-value{
                margin-left: 0px;
                &::before{
                    background: url("@{base-path}/img/footer/language/Vietnam.svg") 0 center no-repeat;
                }
            }
        }
    }
}

.footer-small-drop {
    .ant-select-dropdown {
        padding: 0;
    }
    .ant-select-item {
        min-height: 22px;
        font-size: 12px;
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        padding-top: 0;
        padding-bottom: 0;
    }
    .ant-select-dropdown-menu .ant-select-dropdown-menu-item{
        text-align: left;
        border-bottom: 1px solid #E0E0E0;
        img{
            width: 18px;
            height: auto;
        }
        span{
            margin-left: 5px;
        }
        &:last-child{
            border: 0;
        }
    }
}

.footer-copyright-wrap {
    background-color: #00a6ff;
    font-size: 12px;
    color: #fff;
    padding: 20px 0;
    .copyright-picture {
        padding: 10px 0;
        img {
            width: 122px;
        }
    }
    .copyright-article {
        padding: 16px 0;
    }
    .copyright-content {
        margin: 0;
        text-align: center;
        p {
            margin: 0;
        }
    }
}
// TLC Footer
.ant-modal.tlc-wechat-modal._white .ant-modal-content {
    background-color: #fff;
    color: #000;
    .ant-modal-close {
        color: #000;
    }
}
.ant-modal.tlc-wechat-modal .ant-modal-content {
    background-color: #000;
    color: #fff;
    .ant-modal-body {
        padding: 50px;
        & > img {
            width: 100%;
            margin-top: 10px;
        }
        & > p {
            margin-bottom: 0.2em;
        }
    }
    .ant-modal-close {
        color: #fff;
    }
}
.ant-popover.footer-popover {
    .ant-popover-inner {
        border-radius: 4px;
    }
    .ant-popover-inner-content {
        padding: 6px;
        line-height: 20px;
        text-align: center;
    }
    &.aboutUS{
        .ant-popover-inner-content {
          width: 120px;
        }
    }
}