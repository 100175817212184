//seo footer 的less
.footer-description-wrap {
    background-color: #ffffff;
    padding: 0 5px 0 20px;
    margin-bottom: 36px;
    font-size: 12px;
    height: 108px;
    overflow: hidden;
    border-radius: 10px;

    &.active {
        height: 400px;
    }

    .footer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #EFEFF4;
        margin-bottom: 12px;
        margin-right: 15px;

        .footer-header-title {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .expand-button {
        background: transparent;
        border: none;
        color: #666666;
        padding: 4px;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        img {
            transition: transform 0.3s ease;
            
            &.collapse {
                transform: rotate(180deg);
            }
        }
    }

    .footer-content-body {
        overflow-y: hidden;
        height: 40px;

        /* Custom scrollbar styles */
        &::-webkit-scrollbar {
            width: 6px; /* Width of the scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: transparent; /* Color of the track */
            border-radius: 4px; /* Rounded corners for the track */
        }

        &::-webkit-scrollbar-thumb {
            background: #E0E0E0; /* Color of the scrollbar thumb */
            border-radius: 4px; /* Rounded corners for the thumb */
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555; /* Color of the scrollbar thumb on hover */
        }

        &.active {
            overflow-y: scroll;
            height: 323px;
            margin-bottom: 12px;
        }
    }
}
