#help {
    .box_body {
        height: auto;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 20px;
        //margin-top: 25px;
        margin-bottom: 50px;
        padding: 20px 15px;
        // min-height: 500px;

        img {
            max-width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        caption {
            caption-side: top;
            color: rgb(0, 0, 0);
            font-size: 1.17em;
            font-weight: bold;
            white-space: pre;
        }
    }

    h2 {
        font-weight: bold;
    }

    // .about-content {
    // 	.ant-skeleton-header {
    // 		padding: 10px 20px;

    // 		.ant-skeleton-avatar-lg {
    // 			width: 170px;
    // 			height: 500px;
    // 			border-radius: 15px !important;
    // 		}

    // 	}

    // 	.ant-empty {
    // 		padding-top: 10%;
    // 	}

    // 	.ant-empty-image {
    // 		height: 150px;
    // 	}

    // 	.ant-empty-description {
    // 		margin-top: 20px;
    // 		text-indent: 50px;
    // 	}
    // 	.ant-menu-root {
    // 		width: 200px;
    // 		color: #222222;
    // 		background: #ffffff 0% 0% no-repeat padding-box;
    // 		box-shadow: 0px 0px 2px #00000029;
    // 		border-radius: 20px;
    // 		overflow: hidden;
    // 	}
    // }

    .about-content {
        min-height: 900px;
        display: flex;

        .ant-skeleton-header {
            padding: 10px 20px;

            .ant-skeleton-avatar-lg {
                width: 170px;
                height: 500px;
                border-radius: 15px !important;
            }
        }

        .ant-empty {
            padding-top: 15%;
        }

        .ant-empty-image {
            height: 150px;
        }

        .ant-empty-description {
            margin-top: 20px;
            text-indent: 50px;
        }

        .right {
            flex: 1;
            margin: 0 22px;
            // background: #ffffff 0% 0% no-repeat padding-box;
            // box-shadow: 0px 0px 3px #00000029;
            border-radius: 20px;
            width: 900px;

            h2 {
                margin-bottom: 28px;
            }

            .box_body {
                height: auto;
                background: #ffffff 0% 0% no-repeat padding-box;
                border-radius: 20px;
                //margin-top: 25px;
                margin-bottom: 50px;
                padding: 40px 60px;
                // min-height: 500px;
            }
        }

        .ant-menu {
            color: #222222;
        }

        .ant-menu-root {
            width: 200px;
            color: #222222;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 2px #00000029;
            border-radius: 10px;
            padding: 20px 0;
            overflow: hidden;
        }

        .ant-menu-submenu {
            font-weight: bold;
        }

        .ant-menu-submenu-selected {
            color: #222222;
        }

        .ant-menu-submenu-title:hover {
            color: #222222;
        }

        .ant-menu-item {
            display: flex;
            align-items: center;
            height: 44px;
            font-weight: normal;
            margin: 0;
            padding-left: 37px !important;
        }

        .ant-menu-item-selected {
            color: #00a6ff;
            font-weight: bold;
        }

        .ant-menu-item:hover,
        .ant-menu-item-active,
        .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
        .ant-menu-submenu-active,
        .ant-menu-submenu-title:hover {
            color: #00a6ff;
        }

        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: #e0e0e0;
        }

        .ant-menu-vertical .ant-menu-item::after,
        .ant-menu-vertical-left .ant-menu-item::after,
        .ant-menu-vertical-right .ant-menu-item::after,
        .ant-menu-inline .ant-menu-item::after {
            border-right: 6px solid #00a6ff;
            left: 0 !important;
            right: auto !important;
        }

        .ant-menu-submenu-open.ant-menu-submenu-inline {
            > .ant-menu-submenu-title {
                .ant-menu-submenu-arrow::before {
                    transform: rotate(45deg) translateX(2px);
                    background: #222;
                }

                .ant-menu-submenu-arrow::after {
                    background: #222;
                    transform: rotate(-45deg) translateX(-2px);
                }
            }
        }

        .ant-menu-submenu-inline {
            > .ant-menu-submenu-title {
                .ant-menu-submenu-arrow::before {
                    transform: rotate(-45deg) translateX(2px);
                    background: #222;
                }

                .ant-menu-submenu-arrow::after {
                    transform: rotate(45deg) translateX(-2px);
                    background: #222;
                }
            }
        }

        .ant-timeline-item {
            padding-left: 35px;
        }

        .ant-timeline-item-content {
            top: -15.001px;
        }

        .ant-divider-horizontal {
            margin-bottom: 14px;
        }

        .ant-timeline-item-tail {
            border-color: rgb(227, 36, 36);
        }

        .ant-timeline-item-head-custom {
            padding: 0px 1px;
        }

        .ant-timeline {
            margin-left: 15px;
        }

        .ant-table {
            margin-bottom: 15px;
        }

        .ant-table-tbody > tr td:not(:first-child) {
            transition: all 0.3s, height 0s;
            color: red;
        }
    }

    .ant-menu.tlc-list-toggle {
        width: 838px;
        box-shadow: none;
        margin-top: 16px;

        .ant-menu-submenu {
            border: 1px solid #ddd;
            border-radius: 8px;
            margin-bottom: 10px;
            overflow: hidden;

            .ant-menu-item {
                padding-left: 48px;
                height: auto;
                white-space: normal;
                color: #222 !important;
                cursor: default;
                line-height: 2;
                margin-bottom: 0;
                padding-bottom: 6px;

                li {
                    list-style: initial;
                }
            }
        }
    }

    .help-tabBar {
        .help-tabPane {
        }

        .ant-tabs-nav-wrap {
            justify-content: flex-start;

            .ant-tabs-tab {
                font-size: 15px !important;
                padding: 12px 0;
                margin-right: 14px;
                &.ant-tabs-tab-active {
                    color: #00a6ff !important;
                    font-weight: bold !important;
                }
            }

            .ant-tabs-ink-bar {
                height: 5px;
            }
        }

        .sub-title {
            color: #000;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .ant-tabs-tabpane {
            .content-container_small {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                text-align: justify;

                p {
                    margin-bottom: 0px;
                    color: #333;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                img {
                    margin: 0;
                }

                a {
                    color: #00a6ff;
                }
                h3{
                    color: #000;
                    margin-bottom: 0;
                    font-size: 14px;
                }
            }
        }

        .table-container {
            display: grid;
            grid-template-columns: 124px 154px 154px 184px 154px;
            column-gap: 2px;
            row-gap: 2px;

            &.promotion {
                grid-template-rows: 40px 50px 50px;

                .table-content {
                    font-size: 12px;
                }
            }

            &.deposit {
                grid-template-rows: repeat(10, 40px);
                margin-bottom: 20px;
            }

            &.withdraw {
                grid-template-columns: 124px 374px;
                grid-template-rows: 40px 40px 40px 40px 40px 106px;
            }

            &.membership {
                grid-template-columns: 124px 154px 154px 184px 154px;
                grid-template-rows: repeat(6, 40px);
            }

            &.expiration {
                grid-template-columns: 124px 154px 154px 184px 154px;
                grid-template-rows: repeat(2, 40px) repeat(10, 30px) 40px 107px;
            }

            .table-title {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #369bf2;
                color: #fff;
                font-size: 14px;
                font-weight: bold;
            }

            .table-content {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #e0eefa;
                text-align: center;
            }
        }
    }
}
