#email-verification-expired.common-distance-wrap {
    margin: 0 auto;
    min-width: 1200px;
    min-height: 100vh;
    // background-image: url('@{base-path}/img/maintain/y-so-serious-white.png');
    //background-image: url('@{base-path}/img/closeIcon/Bg@2x.png');
    background-color: #efeff4;
    overflow: hidden;
    .maintain-header-wrap {
        width: 100%;
        height: 120px;
        min-width: 1200px;
        background-color: #00a6ff;
        .common-distance {
            width: 1200px;
            height: 120px;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            .live-server-btn {
                display: grid;
                place-items: center;
                padding-top: 5px;
                .live-service {
                    width: 34px;
                    height: 34px;
                    background-position: 0 -81px;
                    transition: all 0.2s;
                    cursor: pointer;
                    transform: scale(0.86);
                }
            }
        }
    }
}

.emailVerifyPageWrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 10%;
    & img {
        width: 960px;
        height: 480px;
        position: relative;
    }
    .emailVerifyContent{
        padding: 20px;
        position: absolute;
        width: 360px;
        height: 365px;
        background-color: #fff;
        border-radius: 10px;
        top: 190px;
        right: 140px;
    }
    .emailVerifyContentText {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .emailVerifyBtnWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
    .emailVerifyBtn {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
    }
    .emailVerifyBtn:nth-child(1) {
        background-color: #00A6FF;
        color: #FFFFFF;
    }
    .emailVerifyBtn:nth-child(2) {
        color: #00A6FF;
        background-color: #FFFFFF;
        border: 1px solid #00A6FF;
        margin-top: 20px;
    }
}

.emailVerifyPageWrapper-verifysuccess {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 10%;
    & img {
        width: 960px;
        height: 480px;
        position: relative;
    }
    .emailVerifyContent{
        padding: 20px;
        position: absolute;
        width: 360px;
        height: 315px;
        background-color: #fff;
        border-radius: 10px;
        top: 190px;
        right: 140px;
    }
    .emailVerifyContentText {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .emailVerifyBtnWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
    .emailVerifyBtn {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
    }
    .emailVerifyBtn:nth-child(1) {
        background-color: #00A6FF;
        color: #FFFFFF;
    }
    .emailVerifyBtn:nth-child(2) {
        color: #00A6FF;
        background-color: #FFFFFF;
        border: 1px solid #00A6FF;
        margin-top: 20px;
    }
}
