@use autoprefixer;

.Sponsorbox {
    .ant-skeleton-content {
        .ant-skeleton-paragraph li:first-child {
            height: 500px;
            width: 100% !important;
        }

        .ant-skeleton-title {
            height: 65px;
            width: 100% !important;
            border-radius: 35px;
        }
    }

    .Sponsorbottom {
        // padding: 20px 10px;

        .ant-carousel .slick-slide {
            text-align: center;
            height: 500px;
            background: white;
            overflow: hidden;
        }

        .ant-carousel .slick-slide h3 {
            color: #fff;
        }

        .slick-list {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 16px #00000014;
            border-radius: 20px;
        }

        .content {
            display: flex !important;
            justify-content: center;

            //align-items: center;
            .banner_left {
                width: 50%;

                img {
                    object-fit: contain;
                    width: 1120px;
                    height: 500px;
                    scale: 1.1;
                }
            }

            .banner_right {
                display: flex;
                flex-direction: column;
                background: transparent;
                color: #ccc;
                width: 50%;
                text-align: left;
                padding: 30px 50px 50px 0px;
                z-index: 3;

                .banner_right__sponsorHeader {
                    font-weight: bold;

                    .banner_right__sponsorHeader-name {
                        color: #efd088;
                        font-size: 28px;
                    }
                    .banner_right__sponsorHeader-title {
                        color: #fff;
                        font-size: 24px;
                        padding-bottom: 12px;
                    }
                }

                .banner_right__sponsorBody {
                    margin-top: 10px;
                    width: 500px;
                }

                .redirect-to-sponsor {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 300px;
                    height: 54px;
                    background-color: transparent;
                    border: 1px solid #fff;
                    border-radius: 8px;
                    color: #fff;
                    margin-top: 20px;
                    font-size: 16px;
                    white-space: normal;
                    line-height: 25px;
                }

                // .small_banner {
                // 	margin-top: auto;
                // 	margin-left: 0;
                // 	justify-content: space-between;
                // }
            }
        }

        .anticon {
            font-size: 35px;
        }

        // .small_banner img {
        // 	margin: 5px;
        // }
        .special_right h2 {
            text-align: right;
            color: white;
        }

        .special_left h2 {
            text-align: left;
            color: white;
        }

        .special_left p {
            padding-right: 95px;
        }

        .special_right p {
            padding-left: 95px;
            text-align: right;
        }

        .small_box {
            position: relative;
            cursor: pointer;
            // margin: 5px;

            img {
                border-radius: 10px;
            }

            .change_big {
                display: none;
            }

            &:hover {
                .change_big {
                    display: block;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background: #00000033;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                }
            }
        }

        .special_right,
        .special_left {
            width: 50%;
            text-align: left;
            padding: 30px 50px 60px;
            color: white;
        }

        .special_box {
            position: relative;
        }

        .special-content {
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
        }

        .small_banner {
            display: flex;
            // justify-content: center;
            align-items: center;
            margin-top: auto;
            gap: 20px;
        }

        .next_banner {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        .next_banner .n_left {
            height: 88px;
            width: 88px;
            border: 5px solid #f5f5f5;
            border-radius: 50px;
            background: white;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            cursor: pointer;
        }

        .next_banner .n_right {
            height: 88px;
            width: 88px;
            border: 5px solid #f5f5f5;
            border-radius: 50px;
            background: white;
            float: right;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            cursor: pointer;
        }

        .see_all {
            text-align: right;
            padding: 5px !important;
            cursor: pointer;
        }

        .small_branner {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .small_branner li {
            background: red;
            width: 140px;
            height: 90px;
            margin: 5px;
            cursor: pointer;
        }
    }

    .top-container {
        display: flex;
        position: relative;

        .Sponsortop {
            .Sponsortop-container {
                width: 1120px;
                height: 72px;
                white-space: nowrap;
                overflow: hidden;
                margin-bottom: 23px;

                ul {
                    position: relative;
                    left: 0;
                    display: flex;
                    gap: 20px;
                    margin-bottom: 0;
                    transition: left 0.3s;

                    li {
                        min-width: 218px;

                        .navbtn {
                            height: 70px;
                            display: flex;
                            border-radius: 35px;
                            border: 2px solid #d8d8d8;
                            cursor: pointer;
                            background: white;
                            align-items: center;
                            // justify-content: center;

                            .navbtn-container {
                                display: flex;
                                align-items: center;
                                gap: 10px;
                                font-size: 12px;
                                margin-left: 20px;

                                img {
                                    width: 45px;
                                    height: 45px;
                                    // filter: grayscale(100%);
                                }

                                .navbtn-content__title {
                                    font-weight: bold;
                                    white-space: normal;
                                }
                            }

                            &.invert {
                                img {
                                    filter: invert(1);
                                }
                            }

                            &.active {
                                background: transparent
                                    linear-gradient(
                                        111deg,
                                        #00a6ff 0%,
                                        #00a6ff 100%
                                    )
                                    0% 0% no-repeat padding-box !important;
                                border-radius: 80px;
                                color: white;
                                cursor: pointer;
                                border: 0 !important;
                            }

                            .left {
                                width: 25%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            .right {
                                width: 75%;
                                display: flex;
                                align-items: flex-start;
                                flex-flow: column;
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }

        .Sponsortop-left-arrow-btn,
        .Sponsortop-right-arrow-btn {
            width: 40px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            background-color: transparent;

            svg {
                width: 30px;
                height: 30px;
            }
        }

        .Sponsortop-left-arrow-btn {
            position: absolute;
            left: -40px;
        }
    }
}

.common-distance-wrap {
    .ant-btn {
        &.more-vids-btn {
            width: 230px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 30px auto 50px auto;

            .btn-content-container {
                line-height: 0;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }
        }
    }
}
